<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'account-dashboard', params: { brandprofile: this.$route.params.brandprofile, accountId: this.$route.params.accountId } }">Account overview</router-link>
                    &gt; <router-link :to="{ name: 'account-settings', params: { brandprofile: this.$route.params.brandprofile, accountId: this.$route.params.accountId } }">Account settings</router-link>
                </p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="2" class="my-6">
                        <v-toolbar color="red darken-2 white--text" dark flat dense>
                        <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
                            <v-toolbar-title>
                                Delete profile
                            </v-toolbar-title>
                            <v-spacer/>
                            <v-progress-circular indeterminate size="16" width="2" color="white" v-if="!user || !Array.isArray(accountList)"/>
                        </v-toolbar>
                        <v-card-text v-if="user">

                            <v-list class="ma-0 pa-0">
                                <v-list-item v-if="user">
                                    <v-list-item-icon>
                                        <Avatar :attr="mainProfile" :size="28" :color="primaryColor"/>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ user.name }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ user.email }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>

                            <v-alert type="warning" v-if="!isDeleteProfileEnabled">
                                <p class="mb-2 pb-0">
                                    You must close or remove yourself from the following accounts before you can delete your profile:
                                </p>
                                <ul style="list-style-type: none; margin: 0px; padding: 0px;">
                                    <li v-for="openAccount in openAccountList" :key="openAccount.id"><router-link :to="{ name: 'account-dashboard', params: { brandprofile, accountId: openAccount.id } }"><span class="white--text">{{ openAccount.name }}</span></router-link></li>
                                </ul>
                            </v-alert>

                            <p class="mb-0 mt-4" :class="{ 'grey--text': !isDeleteProfileEnabled }">Tap on the button below to delete this profile. When a profile is deleted:</p>
                            <ul class="mt-2 mb-4">
                                <li><span :class="{ 'grey--text': !isDeleteProfileEnabled }">All individual coupons or other offers are cancelled</span></li> <!-- TODO: show only if there are any non-admin users in the account right now; the owner stays on the account because might need ot delete that account also at the client application, or come back and see past transactions while it's still archived -->
                                <li><span :class="{ 'grey--text': !isDeleteProfileEnabled }">No customer support on past orders</span></li> <!-- TODO only show if account has contacts (anything beyond free plan) -->
                                <li><span :class="{ 'grey--text': !isDeleteProfileEnabled }">Any individual credits and discounts will be forfeit</span></li> <!-- TODO only show if account has a credit > 0 ; we don't pay credits out in cash, they can only apply towards future orders ; ALSO, in our terms of service we  need to state that account credits are generally non-refundable, but if any of them ARE refundable that's a situation that blocks closing the account, because customer either needs to get that refund issued immediately before we close or they need to acknowledge that they will forfeit the refund on a separate form and we'll mark it as forefeited and then they can close the account -->
                            </ul>

                            <p class="text-overline mb-0 mt-4" :class="{ 'grey--text': !isDeleteProfileEnabled }">
                                Confirmation
                                <!-- <font-awesome-icon fixed-width :icon="['fas', 'exclamation-triangle']" class="amber--text text--darken-2 text-body-1"/> -->
                            </p>
                            <!-- TODO: we should have a confirmation checkbox for every item to be deleted: "Delete all documents", "Delete all contacts", "Remove all users", "Stop data leak detection", "Delete custom-branded sites", but only show items that apply to their account; for example if their account has any discounts applied, "Forfeit all applied discounts", etc. -->
                            <v-checkbox color="amber darken-2" v-model="deleteConfirmation1" class="mt-0" :disabled="!isDeleteProfileEnabled">
                                <template #label>
                                    <div>
                                        I want to delete this profile (<strong>{{ user.email }}</strong>)
                                    </div>
                                </template>
                            </v-checkbox>
                            <v-checkbox color="amber darken-2" v-model="deleteConfirmation2" class="mt-0" :disabled="!isDeleteProfileEnabled">
                                <template #label>
                                    I understand it cannot be recovered, but I can create a new profile instead.
                                </template>
                            </v-checkbox>
                            <v-checkbox color="amber darken-2" v-model="deleteConfirmation3" class="mt-0" :disabled="!isDeleteProfileEnabled">
                                <template #label>
                                    <div>
                                    I understand this action is not reversible, and that customer support will <strong><em>not</em></strong> be able to help me recover the profile or associated accounts.
                                    </div>
                                </template>
                            </v-checkbox>

                            <v-alert type="warning" border="left" dense v-if="isDeleteProfileConfirmed">
                                <template #prepend>
                                    <font-awesome-icon fixed-width :icon="['fas', 'exclamation-triangle']" class="white--text text-body-1"/>
                                </template>
                                <span class="font-weight-bold ml-2">This action is not reversible.</span>
                            </v-alert>

                            <v-btn color="red" class="white--text" @click="deleteProfile" :disabled="!isDeleteProfileConfirmed">Delete Profile</v-btn>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState, mapGetters } from 'vuex';
// import TextButton from '@/components/TextButton.vue';
import Avatar from '@/components/Avatar.vue';

export default {
    components: {
        Avatar,
        // TextButton,
    },
    data: () => ({
        accountBillingSummary: null,
        isAccountBillingSummaryReady: false, // prevents flicker on related UI items
        domainList: null,
        error: null,
        deleteConfirmation1: false,
        deleteConfirmation2: false,
        deleteConfirmation3: false,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
            brandprofile: (state) => state.brandprofile,
            palette: (state) => state.palette,
            brand: (state) => state.brand,
            accountList: (state) => state.accountList,
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
        }),
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        mainProfile() {
            return { name: this.user?.name, email: this.user?.email };
        },
        openAccountList() {
            if (Array.isArray(this.accountList)) {
                return this.accountList.filter((item) => item.is_open);
            }
            return null;
        },
        isDeleteProfileEnabled() {
            // delete profile is only enabled when user has no open accounts
            return Array.isArray(this.openAccountList) && this.openAccountList.length === 0;
        },
        isDeleteProfileConfirmed() {
            return this.deleteConfirmation1 && this.deleteConfirmation2 && this.deleteConfirmation3;
        },
    },
    methods: {
        async deleteProfile() {
            try {
                this.$store.commit('loading', { deleteProfile: true });
                const response = await this.$client.site(this.brandprofile).user(this.user.id).self.delete({ is_confirmed: this.isDeleteProfileConfirmed });
                if (response?.isDeleted) {
                    this.$bus.$emit('snackbar', { type: 'info', headline: 'Deleted profile', message: this.$route.params.accountId });
                    this.$store.commit('accountList', []);
                    this.$store.dispatch('refresh');
                    this.$nav.replace({ name: 'brand-front' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete profile', message: this.$route.params.accountId });
                }
            } catch (err) {
                console.error('failed to delete profile', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete profile', message: this.$route.params.accountId });
            } finally {
                this.$store.commit('loading', { deleteProfile: false });
            }
        },
    },
    mounted() {
        this.$store.dispatch('loadAccountList');
    },
};
</script>
